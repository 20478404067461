import { Notification } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { parseAirDateToISO } from '@air/utilities';
import { format } from 'date-fns';
import { memo } from 'react';

import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';
import { NotificationShareLinkIcon } from '~/components/AirNotifications/components/NotificationShareLinkIcon';
import { useShortUrlNotificationParentName } from '~/components/AirNotifications/hooks/useShortUrlNotificationParentName';

interface NotificationShareLinkExpiredTypeProps extends Pick<NotificationsItemProps, 'onClick'> {
  notification: Notification<'shareLinkExpired_userIsShareLinkCreator', 'account'>;
}

export const NotificationShareLinkExpiredType = memo(
  ({ notification, onClick }: NotificationShareLinkExpiredTypeProps) => {
    const { shortUrl, expirationDate } = notification.data;

    const date = new Date(parseAirDateToISO(expirationDate));

    const { shortUrlParentName } = useShortUrlNotificationParentName(shortUrl);

    return (
      <NotificationBaseItem
        meta={shortUrlParentName ? `in ${shortUrlParentName}` : ''}
        onClick={onClick}
        notification={notification}
        prefix={<NotificationShareLinkIcon isBroken />}
        title={
          <>
            A link to <b>{shortUrl.objectName}</b> expired on {format(date, 'MMM d')}
          </>
        }
      />
    );
  },
);

NotificationShareLinkExpiredType.displayName = 'NotificationShareLinkExpiredType';
