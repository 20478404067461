import { useTrackVersionUploadInitiated } from '@air/analytics';
import { Clips } from '@air/api';
import { Clip } from '@air/api/types';
import { InputFile, OnUploadParams, UploadDropzone } from '@air/component-upload-dropzone';
import { NO_MORE_STORAGE, SUBSCRIPTION_EXPIRED } from '@air/errors';
import { useAirModal } from '@air/provider-modal';
import { useToasts } from '@air/provider-toast';
import { queueUploadsAction, Upload } from '@air/redux-uploader';
import { createUpload } from '@air/upload-utils';
import { partition, uniqueId } from 'lodash';
import { PropsWithChildren } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { SubscriptionExpiredModal } from '~/components/Modals/SubscriptionExpiredModal';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import {
  centralizedBoardAncestorsSelector,
  centralizedBoardLibrarySelector,
  centralizedBoardTitleSelector,
} from '~/store/centralizedBoard/selectors';
import { centralizedClipAssetIdSelector } from '~/store/centralizedClip/selectors';
import { setPrivateUploadsMetadata } from '~/store/privateUploadMetadata/actions';
import { getFilePath } from '~/store/uploadingPathToBoardId/utils';
import { useCanUploadAssets } from '~/swr-hooks/members/useCanUploadAssets';
import { BoardsSelectOption, BoardsSelectRootOption } from '~/types/BoardSearch';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { sanitizeRestrictedPathChars, sanitizeRestrictedPathCharsInFile } from '~/utils/FileUtils';
import { getBoardIdFromPath, getClipIdFromPath } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

import { DuplicateItem, DuplicateItemModal } from '../Modals/DuplicateItemModal';
import FreeOutOfStorageModal from '../Modals/FreeOutOfStorageModal';
import { MissingFileExtensionsModal } from '../Modals/MissingFileExtenionsModal';
import { PreUploadModal } from '../Modals/PreUploadModal';
import { RestrictedCharacterReplacementModal } from '../Modals/RestrictedCharacterReplacementModal';
import { UploadVersionsModal } from '../Modals/UploadVersionsModal';
import { getSortedFilesAndMetadata, handleRejectedFiles } from './utilities/dropzones';

export type PrivateDropzoneProps = PropsWithChildren<object>;
type UploadAndMetadata = {
  upload: Upload;
  metadata: { assetId?: string; isVersion: boolean; originBoard?: BoardsSelectRootOption; filepath: string };
};

export const PrivateDropzone = ({ children }: PrivateDropzoneProps) => {
  const store = useAirStore();
  const dispatch = useDispatch();
  const { showToast } = useToasts();
  const currentAssetId = useSelector(centralizedClipAssetIdSelector);
  const { canUploadAssets } = useCanUploadAssets();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const [showDuplicateItemModal] = useAirModal(DuplicateItemModal);
  const [showRestrictedCharacterConfirmationModal] = useAirModal(RestrictedCharacterReplacementModal);
  const [showMissingFileExtensionsModal] = useAirModal(MissingFileExtensionsModal);
  const [showPreUploadModal] = useAirModal(PreUploadModal);
  const [showFreeOutOfStorageModal] = useAirModal(FreeOutOfStorageModal);
  const [showSubscriptionExpiredModal] = useAirModal(SubscriptionExpiredModal);
  const [showUploadVersionsModal, closeUploadVersionsModal] = useAirModal(UploadVersionsModal);
  const { trackVersionUploadInitiated } = useTrackVersionUploadInitiated();

  const checkIfCanUpload = async ({ files, totalSize }: { totalSize: number; files: InputFile[] }) => {
    if (!workspaceId) {
      throw new Error('No workspace id');
    }

    try {
      const { canUpload, failureReason } = await Clips.checkCanUpload({ workspaceId, size: totalSize });

      if (!canUpload) {
        switch (failureReason) {
          case NO_MORE_STORAGE.message:
            showFreeOutOfStorageModal();
            break;
          case SUBSCRIPTION_EXPIRED.message:
            showSubscriptionExpiredModal();
            break;
          default:
            throw new Error(`Expected failureReason ${NO_MORE_STORAGE.message}, Received: ${failureReason}`);
        }
      }
      return canUpload;
    } catch (error: unknown) {
      showToast('There was an error while trying to upload items, please try again later');
      reportErrorToBugsnag({
        error,
        context: `Failed to upload items`,
        metadata: { data: { files: files.map((f) => ({ name: f.name, size: f.size })) } },
      });
      return false;
    }
  };

  const onUpload = async ({ acceptedFiles, rejectedFiles }: OnUploadParams) => {
    let shouldCancelUpload = false;
    let selectedBoard: null | BoardsSelectOption = null;
    /**
     * `isModalVersion` is a version upload from the `VersionsUploadModal` from the AssetModal
     * as opposed to an auto-versioning prompt based on a duplicate item name or title
     */
    let isModalVersion = false;
    let assetId: Clip['assetId'];

    const { emptyFiles, filesWithNoExtension, filesWithRestrictedChars, files, totalSize } =
      getSortedFilesAndMetadata(acceptedFiles);
    const currentBoardId = getBoardIdFromPath(window.location.pathname);
    const currentBoardTitle = centralizedBoardTitleSelector(store.getState());
    const currentBoardLibrary = centralizedBoardLibrarySelector(store.getState());
    const clipIdInUrl = getClipIdFromPath(window.location.pathname);

    const canUpload = await checkIfCanUpload({ files, totalSize });
    if (!canUpload) {
      return;
    }

    // If there are any files without extensions, prompt the user to cancel or continue without those files.
    if (filesWithNoExtension.length) {
      shouldCancelUpload = await new Promise((resolve) => {
        showMissingFileExtensionsModal({
          items: filesWithNoExtension,
          onCancel: () => {
            resolve(true);
          },
          onConfirm: () => {
            resolve(false);
          },
        });
      });
      if (shouldCancelUpload) {
        return;
      }
    }

    // If there are any file with names that contain restricted characters, prompt the user to confirm the replacement of these characters.
    if (filesWithRestrictedChars.length) {
      shouldCancelUpload = await new Promise((resolve) => {
        showRestrictedCharacterConfirmationModal({
          items: filesWithRestrictedChars,
          onCancel: () => {
            resolve(true);
          },
          onConfirm: () => {
            resolve(false);
          },
        });
      });

      if (shouldCancelUpload) {
        return;
      } else {
        const sanitizedFiles = filesWithRestrictedChars.map((file) => sanitizeRestrictedPathCharsInFile(file));
        files.push(...sanitizedFiles);
      }
    }

    /** I tried to refactor the dropzones and wrap a new one around private assets
     * The issue is that even moving this current dropzone from wrapping Home
     * to wrapping boards individually causes there to be dropzone
     * overlap and versions were being uploaded twice despite only one file being accepted.
     * I was spending too much time on it so used this shortcut for now*/
    //Only add assetId to make upload a version if uploading to an asset
    if (!!clipIdInUrl) {
      isModalVersion = await new Promise<boolean>((accept) =>
        showUploadVersionsModal({
          onUpload: () => accept(true),
          onCancel: () => {
            shouldCancelUpload = true;
            accept(false);
          },
          files,
        }),
      );

      closeUploadVersionsModal();

      if (isModalVersion) {
        assetId = currentAssetId;
        trackVersionUploadInitiated();
      }
    } else {
      selectedBoard = await new Promise<BoardsSelectOption | null>((accept) => {
        // This will allow the modal to show deeply nested boards in
        // tree view.
        const currentBoardAncestors = centralizedBoardAncestorsSelector(store.getState());

        showPreUploadModal({
          onUpload: accept,
          onCancel: () => {
            shouldCancelUpload = true;
            accept(null);
          },
          initialBoard:
            currentBoardId && currentBoardTitle && currentBoardAncestors
              ? {
                  id: currentBoardId,
                  library: currentBoardLibrary,
                  title: currentBoardTitle,
                  ancestors: currentBoardAncestors,
                }
              : undefined,
        });
      });
    }

    if (shouldCancelUpload) {
      return;
    }

    const [filesInFolders, filesNotInFolders] = partition(files, (f) => f.path?.includes('/'));
    let duplicateHandlingData: DuplicateItem[] = [];

    if (filesNotInFolders.length) {
      /**
       * This endpoint takes the files that the user is trying to upload and checks
       * if any of the other assets in that board have the same filename.
       */
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      const { data: matchedFilesToClips } = await Clips.matchFilesToClips({
        workspaceId,
        files: filesNotInFolders.map(({ name }) => ({ name })),
        boardId: selectedBoard?.id,
      });

      /**
       * We store `index` because `Clips.matchFilesToClips` returns an array in the original order, matching `files` to `Clips` uploaded
       * `matchedFilesToClips` matched them by file.name
       */
      const duplicateFiles = matchedFilesToClips.map((d, index) => ({ ...d, index })).filter((item) => !!item.assetId);

      if (duplicateFiles.length > 0 && selectedBoard && !isModalVersion) {
        duplicateHandlingData = await new Promise((accept) => {
          showDuplicateItemModal({
            items: duplicateFiles,
            onComplete: accept,
            onCancel: () => {
              shouldCancelUpload = true;
              accept([]);
            },
          });
        });
      }
    }

    if (shouldCancelUpload) {
      return;
    }

    const batchId = uniqueId('batch');
    const duplicateHandlingDataDict = duplicateHandlingData.reduce<Record<number, DuplicateItem>>((acc, item) => {
      acc[item.index] = item;
      return acc;
    }, {});

    const folderUploads = filesInFolders.map((f) =>
      createUpload({
        file: f,
        batchId,
      }),
    );

    const boardToUpload = selectedBoard
      ? selectedBoard
      : currentBoardId && currentBoardTitle
      ? { title: currentBoardTitle, id: currentBoardId }
      : undefined;

    const topLevelUploadsAndMetadata = filesNotInFolders.reduce<UploadAndMetadata[]>((acc, f, index) => {
      const duplicateDecision = duplicateHandlingDataDict[index];

      if (duplicateDecision && duplicateDecision.choice === 'cancel') {
        return acc;
      }

      const assetIdDestination = isModalVersion
        ? assetId
        : duplicateDecision?.choice === 'stack'
        ? duplicateDecision.assetId
        : undefined;

      const filepath = f.path ? getFilePath(f.path) : '';

      acc.push({
        upload: createUpload({
          file: f,
          batchId,
        }),
        metadata: {
          assetId: assetIdDestination,
          isVersion: Boolean(assetIdDestination),
          originBoard: boardToUpload,
          filepath,
        },
      });

      return acc;
    }, []);

    const topLevelUploads = topLevelUploadsAndMetadata.map(({ upload }) => upload);
    const fileUploadsMetadata = topLevelUploadsAndMetadata.map(({ upload, metadata }) => {
      return {
        uploadId: upload.id,
        metadata,
      };
    });

    const folderUploadsMetadata = folderUploads.map((upload) => {
      const filepath = sanitizeRestrictedPathChars(upload.file.path ? getFilePath(upload.file.path) : '');

      return {
        uploadId: upload.id,
        metadata: {
          isVersion: false,
          originBoard: boardToUpload,
          filepath,
        },
      };
    });

    batch(() => {
      dispatch(queueUploadsAction({ uploads: [...folderUploads, ...topLevelUploads] }));
      dispatch(setPrivateUploadsMetadata({ uploadsMetadata: [...folderUploadsMetadata, ...fileUploadsMetadata] }));
    });

    if (rejectedFiles.length + emptyFiles.length + filesWithNoExtension.length > 0) {
      dispatch(handleRejectedFiles({ rejectedFiles, emptyFiles, filesWithNoExtension }));
    }
  };

  return (
    <UploadDropzone onUpload={onUpload} disabled={!canUploadAssets}>
      {children}
    </UploadDropzone>
  );
};
