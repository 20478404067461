import { Button } from '@air/primitive-button';
import React from 'react';

import { BirdsCarryingSackAsset } from '~/components/Assets/BirdsCarryingSack';

export const SSOInProgress = () => {
  return (
    <>
      <BirdsCarryingSackAsset className="mb-5" />
      <h1 className="mb-5 max-w-xl text-center font-gelica text-32 md:mb-7 md:text-40">Securely signing you in...</h1>
      <p className="mb-5 max-w-md text-16">
        We’re verifying your identity. You’ll be redirected to your workspace within 30 seconds.
      </p>
      <Button isLoading disabled size="large">
        Logging in...
      </Button>
    </>
  );
};
