import { MobileNavigationContextProvider } from '@air/mobile-navigation';
import { ActionBarProvider } from '@air/provider-action-bar';
import { useBreakpointsContext } from '@air/provider-media-query';
import { ModalProvider } from '@air/provider-modal';
import { useToasts } from '@air/provider-toast';
import { SysadminProvider } from '@air/sysadmin';
import { ErrorBoundary } from '@air/utils-error';
import { ConnectedRouter } from 'connected-next-router';
import { memo, ReactNode } from 'react';

import { AccountProvisionLayout } from '~/components/AccountProvisioner/components/AccountProvisionLayout';
import { AccountProvisionerProvider } from '~/components/AccountProvisioner/providers/AccountProvisionerProvider';
import { Banners } from '~/components/Layouts/HomeLayout/Banners';
import { useConfigureThirdPartyServices } from '~/components/Layouts/HomeLayout/hooks/useConfigureThirdPartyServices';
import { useHandleConfigureSignoutUrl } from '~/components/Layouts/HomeLayout/hooks/useHandleConfigureSignoutUrl.ts';
import { MaintenanceMode } from '~/components/MaintenanceMode/MaintenanceMode';
import { CoreMobileNavigation } from '~/components/Mobile/CoreMobileNavigation';
import { PrivateDropzone } from '~/components/PrivateDropzone/PrivateDropzone';
import { PrivatePageTitle } from '~/components/PrivatePageTitle';
import { PrivateReduxProvider } from '~/components/PrivateReduxProvider';
import { PrivateWorkspaceActivityContainer } from '~/components/PrivateWorkspaceActivityContainer';
import { ReactDndDragLayer } from '~/components/ReactDndDragLayer/ReactDndDragLayer';
import { MainContent } from '~/components/Shared/ClipGallery/Content';
import OverflowHiddenGlobalStyle from '~/components/Shared/OverflowHiddenGlobalStyle';
import { MAIN } from '~/constants/testIDs';
import { useOnLocationChange } from '~/hooks/router/useOnLocationChange';
import { BillingCycleProvider } from '~/hooks/useBillingCycle';
import { useHandleSessionExpired } from '~/hooks/useHandleSessionExpired';
import { ROOT_ELEMENT_ID } from '~/hooks/useMouseSelection';
import { ThumbnailPreferenceContextProvider } from '~/hooks/useThumbnailPreference';
import { AccountProvider } from '~/providers/AccountProvider';
import { CannyProvider } from '~/providers/CannyProvider';
import { CurrentWorkspaceMemberProvider } from '~/providers/CurrentWorkspaceMemberProvider';
import { CurrentWorkspacePermissionsProvider } from '~/providers/CurrentWorkspacePermissionsProvider';
import { CurrentWorkspaceProvider, useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { DesktopAppConnectionProvider } from '~/providers/DesktopAppConnectionProvider';
import { FiltersProvider, GetImmutableFilters } from '~/providers/FiltersProvider';
import { InactivityProvider } from '~/providers/InactivityProvider';
import { ModifiersKeysPressedProvider } from '~/providers/ModifiersKeysPressedProvider';
import { PlanProvider } from '~/providers/PlanProvider';
import { PrivateCustomFieldsProvider } from '~/providers/PrivateCustomFieldsProvider';
import { SearchHeaderHeightProvider } from '~/providers/SearchHeaderHeightProvider';
import { SideNavProvider } from '~/providers/SideNavProvider';
import { SubscriptionPlanProvider } from '~/providers/SubscriptionPlanProvider';
import { SubscriptionProvider } from '~/providers/SubscriptionProvider';
import { WorkspaceMembersProvider } from '~/providers/WorkspaceMembersProvider';
import { WorkspaceRolesProvider } from '~/providers/WorkspaceRolesProvider';

import { PrivateSocketContextProvider } from '../../PrivateSocketContextProvider';
import { GlobalStyles } from '../GlobalStyles';
import { useCheckIfUserHasWorkspaces } from './hooks/useCheckIfUserHasWorkspaces';
import { useHandleInactivity } from './hooks/useHandleInactivity';
import { useHandleUnauthenticatedHomeLayoutUser } from './hooks/useHandleUnauthenticatedHomeLayoutUser.ts';
import { useHandleUserNeedsRedirect } from './hooks/useHandleUserNeedsRedirect';
import { useNotificationsEvent } from './hooks/useNotificationsEvent';
import { SideNav } from './SideNav/SideNav';

interface HomeLayoutProps {
  children: ReactNode;
}

const HomeLayout = memo(({ children }: HomeLayoutProps) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { dismissAllToasts } = useToasts();

  useHandleConfigureSignoutUrl();
  useHandleInactivity();
  useCheckIfUserHasWorkspaces();
  useHandleUserNeedsRedirect();
  useHandleUnauthenticatedHomeLayoutUser();
  useNotificationsEvent();
  useHandleSessionExpired();
  useOnLocationChange(dismissAllToasts);
  useConfigureThirdPartyServices();

  return (
    <>
      <OverflowHiddenGlobalStyle />
      <div className="relative flex h-full overflow-y-hidden">
        <PrivatePageTitle title={currentWorkspace?.name} />

        {isAboveMediumScreen && <SideNav />}
        <MainContent className="bg-grey-1" id="main" data-testid={MAIN}>
          {currentWorkspace && <Banners />}
          {children}
        </MainContent>

        {!isAboveMediumScreen && <CoreMobileNavigation />}

        <PrivateWorkspaceActivityContainer />
      </div>
    </>
  );
});

HomeLayout.displayName = 'HomeLayout';

export const getLayout = (page: ReactNode, getImmutableFilters?: GetImmutableFilters) => (
  <div className="h-full" id={ROOT_ELEMENT_ID}>
    <AccountProvisionerProvider>
      <AccountProvisionLayout>
        <AccountProvider>
          <PrivateReduxProvider>
            <>
              <GlobalStyles />
              <ErrorBoundary>
                <ConnectedRouter>
                  <SysadminProvider>
                    <BillingCycleProvider>
                      <CurrentWorkspaceProvider>
                        <CurrentWorkspacePermissionsProvider>
                          <SearchHeaderHeightProvider>
                            <SubscriptionProvider>
                              <WorkspaceMembersProvider>
                                <WorkspaceRolesProvider>
                                  <CurrentWorkspaceMemberProvider>
                                    <PrivateCustomFieldsProvider>
                                      <BillingCycleProvider>
                                        <InactivityProvider>
                                          <FiltersProvider getImmutableFilters={getImmutableFilters}>
                                            <ModifiersKeysPressedProvider>
                                              <ModalProvider>
                                                <PrivateDropzone>
                                                  <PrivateSocketContextProvider>
                                                    <SubscriptionPlanProvider>
                                                      <PlanProvider>
                                                        <CannyProvider>
                                                          <DesktopAppConnectionProvider>
                                                            <ActionBarProvider>
                                                              <SideNavProvider>
                                                                <MobileNavigationContextProvider>
                                                                  <ThumbnailPreferenceContextProvider>
                                                                    <MaintenanceMode>
                                                                      <HomeLayout>{page}</HomeLayout>
                                                                    </MaintenanceMode>
                                                                  </ThumbnailPreferenceContextProvider>
                                                                </MobileNavigationContextProvider>
                                                              </SideNavProvider>
                                                            </ActionBarProvider>
                                                          </DesktopAppConnectionProvider>
                                                        </CannyProvider>
                                                      </PlanProvider>
                                                    </SubscriptionPlanProvider>
                                                  </PrivateSocketContextProvider>
                                                </PrivateDropzone>
                                                <ReactDndDragLayer />
                                              </ModalProvider>
                                            </ModifiersKeysPressedProvider>
                                          </FiltersProvider>
                                        </InactivityProvider>
                                      </BillingCycleProvider>
                                    </PrivateCustomFieldsProvider>
                                  </CurrentWorkspaceMemberProvider>
                                </WorkspaceRolesProvider>
                              </WorkspaceMembersProvider>
                            </SubscriptionProvider>
                          </SearchHeaderHeightProvider>
                        </CurrentWorkspacePermissionsProvider>
                      </CurrentWorkspaceProvider>
                    </BillingCycleProvider>
                  </SysadminProvider>
                </ConnectedRouter>
              </ErrorBoundary>
            </>
          </PrivateReduxProvider>
        </AccountProvider>
      </AccountProvisionLayout>
    </AccountProvisionerProvider>
  </div>
);
