import { Tasks } from '@air/api';
import { useToasts } from '@air/provider-toast';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccountProvisionerKey, useAccountProvisioner } from './useAccountProvisioner';

type CreateAccountProvisionerResponse = Awaited<ReturnType<typeof Tasks.createTask<undefined, 'AccountProvisioner'>>>;
type UseAccountProvisionerCreateMutation = {
  onError?: (error: unknown) => void;
  onSuccess?: (task: CreateAccountProvisionerResponse) => void;
};

export const useAccountProvisionerCreateMutation = ({
  onError,
  onSuccess,
}: UseAccountProvisionerCreateMutation = {}) => {
  const { showToast } = useToasts();
  const { data } = useAccountProvisioner();
  const queryClient = useQueryClient();

  return useMutation<CreateAccountProvisionerResponse>({
    mutationFn: () => {
      if (!data) {
        throw new Error('Account is not federated or already provisioned');
      }
      return Tasks.createTask({
        task: {
          type: 'AccountProvisioner',
          args: data.args,
        },
      });
    },

    onError: (error) => {
      showToast('An error occurred - please try again later');

      reportErrorToBugsnag({
        error,
        context: 'Unable to create AccountProvisioner task',
        metadata: {
          key: 'data',
          data: data?.args,
        },
      });

      onError?.(error);
    },

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getAccountProvisionerKey() });

      onSuccess?.(data);
    },
  });
};
