import { LAST_ADMIN_ERROR, OVER_MAX_MEMBERS, SUBSCRIPTION_EXPIRED } from '@air/errors';
import { AlertBanner } from '@air/primitive-alert-banner';
import { memo } from 'react';

import { useAccountProvisionerCreateMutation } from '../hooks/useAccountProvisionerCreateMutation';
import { useAccountProvisionerContext } from '../providers/AccountProvisionerProvider';

const NonRetryableErrors: string[] = [LAST_ADMIN_ERROR.type, OVER_MAX_MEMBERS.type, SUBSCRIPTION_EXPIRED.type];

export const AccountProvisionBanner = memo(() => {
  const { accountProvisioner, hasError } = useAccountProvisionerContext();
  const { mutateAsync } = useAccountProvisionerCreateMutation();

  const showRetry = !NonRetryableErrors.includes(accountProvisioner?.data.log?.[0] || '');

  if (!accountProvisioner || !hasError) {
    return null;
  }

  return (
    <AlertBanner color="red">
      We encountered an issue while attempting to access your organization&apos;s workspaces. This could be due to a
      temporary system problem or an access-related issue. If it persists please contact your administrator.{' '}
      {showRetry ? (
        <button className="font-semibold hover:underline" onClick={() => mutateAsync()}>
          Retry
        </button>
      ) : null}
    </AlertBanner>
  );
});

AccountProvisionBanner.displayName = 'AccountProvisionBanner';
