import { useTrackClickedDeleteInviteLink, useTrackCopiedInviteMemberLink } from '@air/analytics';
import { WorkspaceUserRole } from '@air/api/types';
import { parseAirDateToISO } from '@air/utilities';
import { formatDistanceToNowStrict } from 'date-fns';

import { CopyField } from '~/components/UI/CopyField';
import { Routes } from '~/constants/routes';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useWorkspaceRolesContext } from '~/providers/WorkspaceRolesProvider';
import { useDeleteInviteToken } from '~/swr-hooks/members/useDeleteInviteToken';
import { useInviteTokens } from '~/swr-hooks/members/useInviteTokens';
import { canDeleteWorkspaceInviteLink } from '~/utils/permissions/workspacePermissions';

interface InviteLinksListProps {
  roles: WorkspaceUserRole[];
}

export const InviteLinksList = ({ roles }: InviteLinksListProps) => {
  const { data = [] } = useInviteTokens();
  const { deleteInviteToken } = useDeleteInviteToken();
  const { data: allRoles } = useWorkspaceRolesContext();
  const { trackClickedDeleteInviteLink } = useTrackClickedDeleteInviteLink();
  const { trackCopiedInviteMemberLink } = useTrackCopiedInviteMemberLink();
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();

  if (!roles.length || !data) return null;

  return (
    <div className="mt-6">
      {Boolean(data.length) && <div className="mb-2.5 text-14 font-semibold text-pigeon-500">Active Invite Links</div>}

      {data?.map((item, index) => {
        const createdAt = parseAirDateToISO(item.createdAt);

        const url = `${window.location.origin}${Routes.joinWorkspaceWithToken.replace('[shortId]', item.value)}`;

        const menuActions = [];

        if (canDeleteWorkspaceInviteLink(workspacePermissions)) {
          menuActions.push({
            text: 'Delete Link',
            action: () => {
              deleteInviteToken(item.id);
              trackClickedDeleteInviteLink();
            },
          });
        }

        return (
          <div className="mb-4" key={index}>
            <div className="mt-1.5 flex shrink-0 items-center">
              <CopyField
                url={url}
                menuActions={menuActions}
                onCopy={() => {
                  const role = allRoles?.find((role) => role.id === item.roleId);
                  trackCopiedInviteMemberLink({ roleId: item.roleId, role: role?.displayName });
                }}
              />
            </div>
            <div className="mt-1.5 flex items-center">
              <div className="text-12 text-pigeon-400">
                {roles.find((role) => role.id === item.roleId)?.displayName} permissions
              </div>
              <span className="mx-1.5 h-[3px] w-[3px] rounded-full bg-pigeon-400" />
              <div className="text-12 text-pigeon-400">
                created {formatDistanceToNowStrict(createdAt, { addSuffix: true })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
