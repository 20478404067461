import { BoxLogo, BrandfolderLogo, Dropbox as DropboxIcon, GoogleDrive } from '@air/next-icons';
import { tailwindMerge } from '@air/tailwind-variants';

export interface ImportIconProps {
  isActive: boolean;
  className?: string;
}

const sourceImageClassName = 'mr-2.5 text-grey-9 w-6 h-6';

export const GoogleDriveImportIcon = ({ isActive, className }: ImportIconProps) => {
  return (
    <GoogleDrive className={tailwindMerge(sourceImageClassName, isActive ? '' : '[&>path]:fill-grey-9', className)} />
  );
};

export const DropboxImportIcon = ({ isActive, className }: ImportIconProps) => {
  return <DropboxIcon className={tailwindMerge(sourceImageClassName, isActive ? 'text-[#0061FF]' : '', className)} />;
};

export const HomeBoxImportIcon = ({ isActive, className }: ImportIconProps) => {
  return <BoxLogo className={tailwindMerge(sourceImageClassName, isActive ? 'text-[#0061FF]' : '', className)} />;
};

export const BrandfolderImportIcon = ({ isActive, className }: ImportIconProps) => {
  return (
    <BrandfolderLogo className={tailwindMerge(sourceImageClassName, isActive ? 'text-[#0061FF]' : '', className)} />
  );
};
