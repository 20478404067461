import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentPropsWithoutRef, memo } from 'react';

type BirdsCarryingSackAssetProps = Pick<ComponentPropsWithoutRef<'svg'>, 'className'>;

export const BirdsCarryingSackAsset = memo(({ className }: BirdsCarryingSackAssetProps) => {
  return (
    <svg
      className={tailwindMerge('h-[170px] w-[170px]', className)}
      width="170"
      height="170"
      viewBox="0 0 170 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_357_21614)">
        <path
          d="M57.4333 126.755C55.6262 125.553 55.1576 123.082 56.3624 121.279C57.5672 119.475 60.0436 119.008 61.8508 120.21"
          stroke="var(--colors-grey6)"
          strokeWidth="3.52344"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M141.967 84.8148C143.742 84.8148 145.18 83.3796 145.18 81.6092C145.18 79.8388 143.742 78.4036 141.967 78.4036C140.193 78.4036 138.755 79.8388 138.755 81.6092C138.755 83.3796 140.193 84.8148 141.967 84.8148Z"
          fill="var(--colors-grey7)"
        />
        <path
          d="M108.08 28.3899C108.967 28.3899 109.686 27.6723 109.686 26.7871C109.686 25.9019 108.967 25.1843 108.08 25.1843C107.193 25.1843 106.474 25.9019 106.474 26.7871C106.474 27.6723 107.193 28.3899 108.08 28.3899Z"
          fill="var(--colors-grey6)"
        />
        <path
          d="M101.139 128.357C102.026 128.357 102.746 127.64 102.746 126.755C102.746 125.869 102.026 125.152 101.139 125.152C100.252 125.152 99.533 125.869 99.533 126.755C99.533 127.64 100.252 128.357 101.139 128.357Z"
          fill="var(--colors-grey7)"
        />
        <path
          d="M54.3593 12.606C56.8729 12.606 58.9106 10.5728 58.9106 8.0647C58.9106 5.55663 56.8729 3.52344 54.3593 3.52344C51.8457 3.52344 49.808 5.55663 49.808 8.0647C49.808 10.5728 51.8457 12.606 54.3593 12.606Z"
          stroke="var(--colors-grey8)"
          strokeWidth="2.11407"
          strokeMiterlimit="10"
        />
        <path
          d="M22.0181 36.6344H47.9873C49.6606 36.6344 51.0662 35.2319 51.0662 33.5623C51.0662 31.8927 49.6606 30.4903 47.9873 30.4903H43.8376C42.4321 30.4903 41.2942 29.355 41.2942 27.9525V27.8857C41.2942 24.1459 38.2823 21.1406 34.5342 21.1406C30.7861 21.1406 27.7742 24.1459 27.7742 27.8857V29.355C27.7742 31.3585 26.1678 32.9613 24.1599 32.9613H22.0181C21.0141 32.9613 20.2109 33.7627 20.2109 34.7644C20.2109 35.833 21.0141 36.6344 22.0181 36.6344Z"
          fill="var(--colors-grey4)"
          stroke="var(--colors-grey4)"
          strokeWidth="1.62093"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M142.482 35.2344C143.419 50.7281 138.466 61.547 138.466 61.547L127.403 60.0133L126.619 48.7246C129.564 40.577 142.482 35.2344 142.482 35.2344Z"
          fill="var(--colors-grey1)"
        />
        <path
          d="M114.961 61.9963H102.98C104.921 71.1456 118.307 77.6236 130.288 76.6218C145.548 75.2862 149.095 65.2019 149.965 60.9277L134.973 60.9945"
          fill="var(--colors-grey1)"
        />
        <path
          d="M135.844 64.6009C132.297 44.0984 106.528 35.4834 106.528 35.4834C106.528 35.4834 106.394 50.1757 110.276 57.5887C114.292 65.202 119.647 66.4709 120.383 64.0667C120.985 61.9964 117.572 62.0632 115.229 62.0632C112.886 62.0632 103.048 62.0632 103.048 62.0632"
          fill="var(--colors-grey1)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.4466 58.0726L45.7719 56.1936C50.4571 50.1831 59.8944 45.4415 59.8944 45.4415C59.8944 58.0966 56.2821 69.0479 54.3531 73.9938L67.1966 73.9366C66.3265 78.2107 62.7791 88.295 47.5188 89.6306C35.5382 90.6324 22.1519 84.1544 20.2109 75.0051L20.2787 75.0051H33.8543C32.7329 73.5839 31.7647 71.6227 31.2554 69.0614C27.6411 51.2302 37.5469 42.2812 37.5469 42.2812C37.5469 42.2812 42.0393 48.4379 46.4466 58.0726Z"
          fill="var(--colors-grey1)"
        />
        <path
          d="M114.961 61.9963H102.98C104.921 71.1456 118.307 77.6236 130.288 76.6218C145.548 75.2862 149.095 65.2019 149.965 60.9277L134.973 60.9945"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M135.844 64.6009C132.297 44.0984 106.528 35.4834 106.528 35.4834C106.528 35.4834 106.394 50.1757 110.276 57.5887C114.292 65.202 119.647 66.4709 120.383 64.0667C120.985 61.9964 117.572 62.0632 115.229 62.0632C112.886 62.0632 103.048 62.0632 103.048 62.0632"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M144.882 64.2671C144.882 64.1555 144.972 64.0681 145.079 64.0681C145.187 64.0681 145.277 64.1555 145.277 64.2671C145.277 64.3786 145.187 64.466 145.079 64.466C144.972 64.466 144.882 64.3786 144.882 64.2671Z"
          fill="var(--colors-grey11)"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
        />
        <path
          d="M136.507 62.5148L136.297 61.5275H137.043L136.507 62.5148Z"
          fill="var(--colors-grey11)"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
        />
        <path
          d="M138.386 60.7274C138.386 60.7274 143.339 49.9085 142.402 34.4148C142.402 34.4148 130.071 39.5149 126.758 47.3483"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.6767 75.3683H20.0188C21.9598 84.5177 35.346 90.9956 47.3267 89.9939C62.587 88.6582 66.1343 78.5739 67.0044 74.2998L52.0119 74.3666"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.8828 77.9061C48.4653 57.8711 37.3548 42.6445 37.3548 42.6445C37.3548 42.6445 27.449 51.5935 31.0632 69.4246C32.7365 77.8393 39.3627 79.7761 40.0989 77.3719C40.7013 75.3016 37.2878 75.3684 34.9452 75.3684C32.6027 75.3684 20.0865 75.3684 20.0865 75.3684"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.854 77.5722C61.854 77.4607 61.9441 77.3733 62.0516 77.3733C62.1591 77.3733 62.2492 77.4607 62.2492 77.5722C62.2492 77.6838 62.1591 77.7712 62.0516 77.7712C61.9441 77.7712 61.854 77.6838 61.854 77.5722Z"
          fill="var(--colors-grey11)"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
        />
        <path
          d="M53.517 75.9104L53.2796 74.8784L54.0765 74.8615L53.517 75.9104Z"
          fill="var(--colors-grey11)"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
        />
        <path
          d="M55.4253 74.0328C55.4253 74.0328 60.0436 61.8782 59.9097 46.5181C59.9097 46.5181 50.4724 51.2597 45.7872 57.2702"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.962 15.5108L106.535 14.7986C106.066 18.0871 103.364 25.3689 96.3174 28.1877C96.0825 33.1205 95.2604 43.5499 93.851 45.8049C92.3706 44.6946 89.6364 42.0535 88.267 38.9153C86.2465 48.5885 84.1703 52.5979 84.1703 52.5979C84.1703 52.5979 75.1036 42.8191 73.3872 28.768C69.4895 27.4377 61.8552 23.2187 59.6736 15.8556L73.9913 15.5327C74.0366 15.3642 74.0831 15.2081 74.1307 15.0657L74.962 15.5108Z"
          fill="var(--colors-grey1)"
        />
        <path
          d="M72.0243 135.77L87.6192 117.405L103.214 135.77C110.108 143.851 109.573 155.872 102.076 163.352C94.1115 171.299 81.1269 171.299 73.1621 163.352C65.6658 155.872 65.1973 143.851 72.0243 135.77Z"
          fill="var(--colors-grey1)"
        />
        <path
          d="M80.926 15.0967L106.159 14.9631C105.368 18.9097 102.304 27.8447 89.8062 30.2189"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.1735 16.0317C60.3782 21.6415 65.9335 26.3164 72.7605 28.7874"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M73.5637 15.2971C71.2211 15.2971 59.1735 16.0317 59.1735 16.0317"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.206 18.9035C101.539 18.9035 101.809 18.6344 101.809 18.3025C101.809 17.9705 101.539 17.7014 101.206 17.7014C100.873 17.7014 100.604 17.9705 100.604 18.3025C100.604 18.6344 100.873 18.9035 101.206 18.9035Z"
          fill="var(--colors-grey11)"
        />
        <path d="M89.3594 29.9895L92.639 29.5888L90.0956 24.9807L89.3594 29.9895Z" fill="var(--colors-grey11)" />
        <path
          d="M95.3832 28.7205C95.7847 42.1439 93.8509 45.4525 93.5091 46.0841C93.5091 46.0841 89.2255 43.4796 87.8199 39.1387"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.0957 24.0456C87.82 44.7485 83.6703 52.7625 83.6703 52.7625C83.6703 52.7625 72.0912 40.274 72.6267 23.2442C72.6936 20.1722 73.0952 16.833 73.6306 15.2302"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.8939 88.2817L87.6862 117.405L89.5603 119.676C89.8949 120.076 90.0957 120.544 90.0957 121.078V121.279C90.0957 122.481 89.0917 123.482 87.887 123.482H87.3515C86.1468 123.482 85.1428 122.481 85.1428 121.279C85.1428 120.744 85.3436 120.277 85.6783 119.876L127.324 76.811"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.0243 135.77L87.6192 117.405L103.214 135.77C110.108 143.851 109.573 155.872 102.076 163.352C94.1115 171.299 81.1269 171.299 73.1621 163.352C65.6658 155.872 65.1973 143.851 72.0243 135.77Z"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.3266 117.286L87.8612 39.8149"
          stroke="var(--colors-grey11)"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M106.762 126.755C107.23 131.229 104.017 135.236 99.533 135.704"
          stroke="var(--colors-grey5)"
          strokeWidth="2.81876"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M138.955 102.913C138.955 101.444 137.751 100.242 136.278 100.242H134.939C133.869 100.242 132.998 99.3735 132.998 98.3049V98.1714C132.998 95.3665 130.723 93.0291 127.845 93.0291C125.034 93.0291 122.691 95.2997 122.691 98.1714C122.691 99.507 121.553 100.642 120.215 100.642H116.333C115.663 100.642 115.128 101.177 115.128 101.844C115.128 102.379 114.659 102.846 114.124 102.846H111.848C111.112 102.846 110.51 103.447 110.51 104.182V104.249C110.51 104.983 111.112 105.584 111.848 105.584H136.144C137.751 105.584 138.955 104.382 138.955 102.913Z"
          fill="var(--colors-grey4)"
          stroke="var(--colors-grey4)"
          strokeWidth="1.62093"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_357_21614">
          <rect width="147.985" height="169.83" fill="var(--colors-grey1)" transform="translate(11.05)" />
        </clipPath>
      </defs>
    </svg>
  );
});

BirdsCarryingSackAsset.displayName = 'BirdsCarryingSackAsset';
