import { MatchFilesToClipsResult } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { Checkbox } from '@air/primitive-checkbox';
import { Modal, ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';
import { omit } from 'lodash';
import pluralize from 'pluralize';
import { useCallback, useEffect, useState } from 'react';

import { DuplicateItemModalContent } from '~/components/Modals/DuplicateItemModal/DuplicateItemModalContent';
import { DuplicateItemChoice } from '~/components/Modals/DuplicateItemModal/DuplicateItemOptions';

export type DuplicateItem = {
  assetId?: string;
  choice?: DuplicateItemChoice;
  index: number;
  name?: string;
};

export interface DuplicateItemModalProps {
  items: (MatchFilesToClipsResult & { index: number })[];
  onComplete: (duplicateHandlingData: DuplicateItem[]) => void;
  onCancel?: () => void;
}

type StateDuplicateItem = DuplicateItem & { key: number };

export const DuplicateItemModal = ({
  items,
  onClose,
  onComplete,
  onCancel,
}: AirModalProps<DuplicateItemModalProps>) => {
  const [duplicateItems, setDuplicateItems] = useState<StateDuplicateItem[]>([]);
  const [activeKey, setActiveKey] = useState(0);
  const [isApplyAll, setIsApplyAll] = useState(false);
  const hasMoreItems = activeKey < items.length - 1;

  useEffect(() => {
    if (duplicateItems.length === 0) {
      setDuplicateItems(
        items.map((item, key) => ({
          ...item,
          key,
          choice: DuplicateItemChoice.Stack,
        })),
      );
    }
  }, [duplicateItems.length, items]);

  const activeItem = duplicateItems.find((items) => items.key === activeKey);

  const onContinue = useCallback(() => {
    if (hasMoreItems && !isApplyAll) {
      setActiveKey(activeKey + 1);
    } else {
      onComplete(
        isApplyAll
          ? duplicateItems.map((item) => {
              if (item.key > activeKey) {
                return {
                  ...omit(item, 'key'),
                  choice: duplicateItems[activeKey].choice,
                };
              }

              return item;
            })
          : duplicateItems,
      );
      onClose();
    }
  }, [activeKey, duplicateItems, hasMoreItems, isApplyAll, onClose, onComplete]);

  const onValueChange = useCallback((item: StateDuplicateItem) => {
    setDuplicateItems((prevState) => {
      return prevState.map((prevItem) => {
        if (prevItem.key === item.key) {
          return item;
        }

        return prevItem;
      });
    });
  }, []);

  return (
    <Modal className="flex flex-col gap-3 overflow-hidden" data-testid="DUPLICATED_ITEM_MODAL" isOpen>
      <header className="flex flex-col gap-2">
        <div className="flex items-center justify-between gap-2">
          <ModalTitle>Duplicate asset name found</ModalTitle>
          <ModalCloseButton
            onClick={() => {
              onCancel?.();
              onClose();
            }}
          />
        </div>
        <ModalDescription className="min-h-[40px]">
          <strong>
            {items[activeKey].name}.{items[activeKey].ext}
          </strong>
          {hasMoreItems && ` (and ${pluralize('more item', items.length - 1 - activeKey, true)})`}
          &nbsp;already exist. How would you like to handle this duplicate?
        </ModalDescription>
      </header>

      {!!duplicateItems.length && (
        <DuplicateItemModalContent
          activeKey={activeKey}
          isApplyAll={isApplyAll}
          items={duplicateItems}
          onValueChange={onValueChange}
        />
      )}

      <footer className="flex justify-between gap-2">
        {hasMoreItems ? (
          <label className="flex items-center gap-2 text-14 font-medium text-grey-12">
            <Checkbox
              checked={isApplyAll}
              onCheckedChange={() => setIsApplyAll(!isApplyAll)}
              data-testid="DUPLICATE_ITEM_MODAL_APPLY_ALL_CHECKBOX"
            />
            {activeKey === 0 ? 'Apply this choice to all duplicates' : 'Apply this choice to all remaining duplicates'}
          </label>
        ) : (
          <div />
        )}
        <Button
          data-testid="DUPLICATED_ITEM_MODAL_PRIMARY_CTA"
          disabled={!activeItem?.choice}
          onClick={onContinue}
          size="large"
        >
          {hasMoreItems && !isApplyAll ? 'Continue' : 'Confirm'}
        </Button>
      </footer>
    </Modal>
  );
};
