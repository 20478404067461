import { ClipSource, ImporterListItem } from '@air/api/types';

import { sharedPaneItemThumbnailClassNames } from '~/components/FileStatusTrackingPane/shared';
import { DropboxImportIcon, GoogleDriveImportIcon, HomeBoxImportIcon } from '~/components/Import/ui';

interface ImportItemIconProps {
  source: ImporterListItem['source'];
}

export const ImportItemIcon = ({ source }: ImportItemIconProps) => {
  switch (source) {
    case ClipSource.dropbox:
      return <DropboxImportIcon isActive={true} className={sharedPaneItemThumbnailClassNames} />;
    case ClipSource.googleDrive:
      return <GoogleDriveImportIcon isActive={true} className={sharedPaneItemThumbnailClassNames} />;
    case ClipSource.box:
      return <HomeBoxImportIcon isActive={true} className={sharedPaneItemThumbnailClassNames} />;
    default:
      return null;
  }
};
