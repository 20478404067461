import { parseAirDateToISO } from '@air/utilities';
import { format } from 'date-fns';
import { memo } from 'react';

import { NotificationCustomFieldSingleSelectValue } from '~/components/AirNotifications/components/NotificationCustomFieldSingleSelectValue';
import { NotificationCustomFieldTextValue } from '~/components/AirNotifications/components/NotificationCustomFieldTextValue';
import { NotificationCustomFieldType } from '~/components/AirNotifications/utils/types';

interface NotificationCustomFieldValueProps {
  state: 'set' | 'unset';
  customField: NotificationCustomFieldType;
}

export const NotificationCustomFieldValue = memo(({ state, customField }: NotificationCustomFieldValueProps) => {
  const value = customField[state];

  if (value?.value) {
    return <NotificationCustomFieldSingleSelectValue state={state} value={value.value} />;
  } else if (value?.dateValue) {
    return <NotificationCustomFieldTextValue value={format(parseAirDateToISO(value.dateValue), 'MM/dd/yy')} />;
  } else if (value?.plainTextValue) {
    return <NotificationCustomFieldTextValue value={value.plainTextValue} />;
  }

  return null;
});

NotificationCustomFieldValue.displayName = 'NotificationCustomFieldValue';
