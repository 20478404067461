import { LAST_ADMIN_ERROR, OVER_MAX_MEMBERS, SUBSCRIPTION_EXPIRED } from '@air/errors';
import { Button } from '@air/primitive-button';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { BirdHoldingUmbrellaAsset } from '~/components/Assets/BirdHoldingUmbrella';
import { Routes } from '~/constants/routes';
import { QueryParamNames } from '~/constants/search';
import { useLogout } from '~/swr-hooks/auth/useLogout';

import { useAccountProvisionerCreateMutation } from '../hooks/useAccountProvisionerCreateMutation';
import { useAccountProvisionerContext } from '../providers/AccountProvisionerProvider';

const getErrorTitle = (error: string) => {
  switch (error) {
    case SUBSCRIPTION_EXPIRED.type:
      return 'Workspace subscription expired';
    case OVER_MAX_MEMBERS.type:
      return 'Workspace member limit reached';
    case LAST_ADMIN_ERROR.type:
      return 'Unable to access workspace';
    default:
      return 'Unable to complete sign-in';
  }
};

const getErrorMessage = (error: string) => {
  switch (error) {
    case SUBSCRIPTION_EXPIRED.type:
      return `Your organization's workspace subscription has expired. Please contact your administrator to resolve this issue.`;
    case OVER_MAX_MEMBERS.type:
      return `Your organization's workspace has reached its maximum member capacity. Please contact your administrator to resolve this issue.`;
    case LAST_ADMIN_ERROR.type:
      return 'Your access to a workspace was revoked, but you were the only Admin. Please contact your Air representative to appoint a new Admin.';
    default:
      return 'We encountered an issue while attempting to access your workspace. This could be due to a temporary system problem or an access-related issue.';
  }
};

export const AccountProvisioningFailedState = () => {
  const { accountProvisioner, setContinuedWithAccountProvisioningError } = useAccountProvisionerContext();
  const { mutateAsync } = useAccountProvisionerCreateMutation();
  const { logout } = useLogout();
  const router = useRouter();
  const redirectUrl = router.query.redirect as string;
  const error = accountProvisioner?.data.log?.[0] || '';

  const errorTitle = getErrorTitle(error);
  const errorMessage = getErrorMessage(error);
  const actionButton = useMemo(() => {
    switch (error) {
      case SUBSCRIPTION_EXPIRED.type:
      case OVER_MAX_MEMBERS.type:
      case LAST_ADMIN_ERROR.type:
        return (
          <Button className="min-w-[150px]" size="large" onClick={() => logout()}>
            Back to login
          </Button>
        );
      default:
        return (
          <Button className="min-w-[150px]" size="large" onClick={() => mutateAsync()}>
            Retry
          </Button>
        );
    }
  }, [error, logout, mutateAsync]);

  return (
    <>
      <BirdHoldingUmbrellaAsset className="mb-5" />
      <h1 className="mb-5 max-w-xl text-center font-gelica text-32 md:mb-7 md:text-40">{errorTitle}</h1>
      <p className="mb-5 max-w-md text-16">{errorMessage}</p>
      <div className="mb-6 mt-4">{actionButton}</div>
      <button
        className="text-14 font-medium text-grey-10 underline hover:text-grey-12"
        onClick={() => {
          setContinuedWithAccountProvisioningError(true);
          router.push(
            !!redirectUrl
              ? `${Routes.joinApprovedWorkspace}?${QueryParamNames.redirect}=${encodeURIComponent(redirectUrl)}`
              : Routes.joinApprovedWorkspace,
          );
        }}
      >
        Join or create a different workspace
      </button>
    </>
  );
};
