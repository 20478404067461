import { memo } from 'react';

import { AccountProvisionBanner } from '~/components/AccountProvisioner/components/AccountProvisionBanner';
import { MaintenanceModeBanner } from '~/components/Banners/MaintenanceModeBanner';
import { VerifyEmailBanner } from '~/components/Banners/VerifyEmail';
import { StripeSubscriptionStatusBanner } from '~/components/SubscriptionStatusBanner';
import { WorkspaceStorageStatusBanner } from '~/components/WorkspaceStorageStatusBanner';
import { BANNERS_CONTAINER } from '~/constants/testIDs';
import { viewingCheckoutPageSelector } from '~/store/router/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export const Banners = memo(() => {
  const isOnCheckoutPage = useAirSelector(viewingCheckoutPageSelector);

  if (isOnCheckoutPage) {
    return null;
  }

  return (
    <div id={BANNERS_CONTAINER}>
      <StripeSubscriptionStatusBanner />
      <WorkspaceStorageStatusBanner />
      <VerifyEmailBanner />
      <MaintenanceModeBanner />
      <AccountProvisionBanner />
    </div>
  );
});

Banners.displayName = 'Banners';
