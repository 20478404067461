import { Notification } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { parseAirDateToISO } from '@air/utilities';
import { format } from 'date-fns';
import { memo } from 'react';

import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';
import { NotificationShareLinkIcon } from '~/components/AirNotifications/components/NotificationShareLinkIcon';
import { useShortUrlNotificationParentName } from '~/components/AirNotifications/hooks/useShortUrlNotificationParentName';

interface NotificationShareLinkExpiringTypeProps extends Pick<NotificationsItemProps, 'onClick'> {
  notification: Notification<'shareLinkExpiring_userIsShareLinkCreator', 'account'>;
}

export const NotificationShareLinkExpiringType = memo(
  ({ notification, onClick }: NotificationShareLinkExpiringTypeProps) => {
    const { shortUrl, expirationDate } = notification.data;

    const date = new Date(parseAirDateToISO(expirationDate));

    const { shortUrlParentName } = useShortUrlNotificationParentName(shortUrl);

    return (
      <NotificationBaseItem
        meta={shortUrlParentName ? `in ${shortUrlParentName}` : ''}
        onClick={onClick}
        notification={notification}
        prefix={<NotificationShareLinkIcon />}
        title={
          <>
            A link to <b>{shortUrl.objectName}</b> will expire on {format(date, 'MMM d')}
          </>
        }
      />
    );
  },
);

NotificationShareLinkExpiringType.displayName = 'NotificationShareLinkExpiringType';
